import classNames from "classnames"
import { GatsbyImage } from "gatsby-plugin-image"
import PropTypes from "prop-types"
import React from "react"

import LocalizedLink from "../../i18n/localized-link"
import { withTranslation } from "../../i18n/translate"
import FullWidthTileContent from "../tile/full-width-tile-content/full-width-tile-content"
import Tile from "../tile/tile"
import TileContentSpacer from "../tile/tile-content-spacer/tile-content-spacer"
import "./location-preview.sass"

class LocationPreview extends React.Component {
  constructor(props) {
    super(props)
    this.previewImageRef = React.createRef()
    this.resizeListener = () => this.handleResize()
  }

  componentDidMount() {
    window.addEventListener("resize", this.resizeListener)
    this.handleResize()
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.resizeListener)
  }

  handleResize() {
    if (this.previewImageRef.current) {
      this.previewImageRef.current.style.height =
        this.previewImageRef.current.clientWidth * 0.5 + "px"
    }
  }

  render() {
    const { t, location } = this.props
    // console.log(location.image_resources[0])
    return (
      <Tile
        tileSize={"s"}
        linkTextTranslatedString={t(
          "MUNZING_GROUP-MUNZING_WORLDWIDE-LOCATIONS-MORE_ABOUT_THIS_LOCATION",
        )}
        linkPath={location.getPageUrl()}
      >
        {location.image_resources && (
          <>
            <FullWidthTileContent>
              <LocalizedLink tabIndex={-1} to={location.getPageUrl()}>
                <div
                  ref={this.previewImageRef}
                  className={"_fp-location-preview__image-container"}
                >
                  {location.image_resources.length > 0 &&
                    location.description && (
                      <GatsbyImage
                        sizes={
                          "(max-width: 600px) 100vw, (min-width: 601px) and (max-width: 900px) 50vw, 33vw"
                        }
                        image={
                          location.image_resources[0].image.localFile
                            .childImageSharp.gatsbyImageData
                        }
                        className={
                          "_fp-location-preview__image-container__image"
                        }
                        alt={t(location.description.key)}
                      />
                    )}
                </div>
              </LocalizedLink>
            </FullWidthTileContent>
            <TileContentSpacer />
          </>
        )}
        <LocalizedLink tabIndex={-1} to={location.getPageUrl()}>
          <h3 className={"_fp-location-preview__title _fp-heading-4"}>
            {t(location.name.key)}
          </h3>
        </LocalizedLink>
        <TileContentSpacer />
        {(location.short_description.key || location.description.key) && (
          <p
            className={classNames(
              "_fp-text _fp-location-preview__description",
              {
                "_fp-location-preview__description--trim":
                  !location.short_description.key,
              },
            )}
          >
            {location.short_description.key
              ? t(location.short_description.key)
              : t(location.description.key)}
          </p>
        )}
        <TileContentSpacer />
      </Tile>
    )
  }
}

LocationPreview.propTypes = {
  location: PropTypes.object.isRequired,
}

export default withTranslation()(LocationPreview)
