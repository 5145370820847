import classNames from "classnames"
import { GatsbyImage } from "gatsby-plugin-image"
import PropTypes from "prop-types"
import React from "react"
import {
  GoogleMap,
  Marker,
  withGoogleMap,
  withScriptjs,
} from "react-google-maps"
import { useDeepCompareEffect } from "react-use"

import LocalizedLink from "../../i18n/localized-link"
import { withTranslation } from "../../i18n/translate"
import { SoftgardenJobModel } from "../../model/career/softgardenJobModel"
import mapStyle from "../map/mapStyle.json"
import FullWidthTileContent from "../tile/full-width-tile-content/full-width-tile-content"
import Tile from "../tile/tile"
import TileContentSpacer from "../tile/tile-content-spacer/tile-content-spacer"
import "./softgarden-location-preview.sass"

const defaultLocation = {
  lat: 49.0719153,
  lng: 9.2858928,
}

const mapsOptions = {
  disableDefaultUI: true,
  // styles: mapStyle
}
const markerOptions = {
  // defaultIcon: "/images/icons/munzing-location-marker.svg"
}

const Map = withScriptjs(
  withGoogleMap((props) => {
    return (
      <GoogleMap
        defaultOptions={{
          mapId: "801f3f008cbca865",
          center: props.location,
          zoom: 10,
          minZoom: 1,
          restriction: {
            latLngBounds: {
              north: 85,
              south: -85,
              east: 180,
              west: -180,
            },
          },
          ...mapsOptions,
        }}
      >
        <Marker position={props.location} {...markerOptions} />
      </GoogleMap>
    )
  }),
)

class SoftgardenLocationPreview extends React.Component {
  constructor(props) {
    super(props)
    this.previewImageRef = React.createRef()
    this.resizeListener = () => this.handleResize()
  }

  componentDidMount() {
    window.addEventListener("resize", this.resizeListener)
    this.handleResize()
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.resizeListener)
  }

  handleResize() {
    if (this.previewImageRef.current) {
      this.previewImageRef.current.style.height =
        this.previewImageRef.current.clientWidth * 0.5 + "px"
    }
  }

  render() {
    const { t, jobOffer } = this.props
    console.log(jobOffer)
    // console.log(location.image_resources[0])
    return (
      <Tile tileSize={"s"}>
        {/*{location.image_resources && (*/}
        {/*  <LocalizedLink tabIndex={-1} to={location.getPageUrl()}>*/}
        {/*    <FullWidthTileContent>*/}
        {/*      <div*/}
        {/*        ref={this.previewImageRef}*/}
        {/*        className={"_fp-softgarden-location-preview__image-container"}*/}
        {/*      >*/}
        {/*        {location.image_resources.length > 0 &&*/}
        {/*          location.description && (*/}
        {/*            <GatsbyImage*/}
        {/*              sizes={*/}
        {/*                "(max-width: 600px) 100vw, (min-width: 601px) and (max-width: 900px) 50vw, 33vw"*/}
        {/*              }*/}
        {/*              image={*/}
        {/*                location.image_resources[0].image.localFile*/}
        {/*                  .childImageSharp.gatsbyImageData*/}
        {/*              }*/}
        {/*              className={"_fp-softgarden-location-preview__image-container__image"}*/}
        {/*              alt={t(location.description.key)}*/}
        {/*            />*/}
        {/*          )}*/}
        {/*      </div>*/}
        {/*    </FullWidthTileContent>*/}
        {/*  </LocalizedLink>*/}
        {/*)}*/}

        <h3 className={"_fp-softgarden-location-preview__title _fp-heading-4"}>
          {jobOffer.geo_name}
        </h3>

        <TileContentSpacer />

        <FullWidthTileContent>
          <Map
            location={{ lat: +jobOffer.geo_lat, lng: +jobOffer.geo_long }}
            googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyD3utzJ6KypMIKU2XOzve3KxJ-H7Q-PkIg&v=3.exp&mapId=801f3f008cbca865"
            loadingElement={
              <div className={"_fp-softgarden-location-preview__map"} />
            }
            containerElement={
              <div className={"_fp-softgarden-location-preview__map"} />
            }
            mapElement={<div style={{ height: `100%` }} />}
          />
        </FullWidthTileContent>
      </Tile>
    )
  }
}

SoftgardenLocationPreview.propTypes = {
  jobOffer: PropTypes.instanceOf(SoftgardenJobModel).isRequired,
}

export default withTranslation()(SoftgardenLocationPreview)
