import classNames from "classnames"
import { GatsbyImage } from "gatsby-plugin-image"
import PropTypes from "prop-types"
import React from "react"

import { useTranslation } from "../../../i18n/translate"
import EmployeesIcon from "../../../images/icons/employees.svg"
import useIsClient from "../../../util/is-client"
import FullSizeTileContent from "../../tile/full-size-tile-content/full-size-tile-content"
import Tile from "../../tile/tile"
import "./career-contact.sass"

const CareerContact = ({ contact }) => {
  const { t } = useTranslation()
  const isClient = useIsClient()

  const hasImage = !!contact.gatsbyImage

  return (
    <div className={"_fp-career-contact"}>
      <Tile>
        <FullSizeTileContent>
          <div className={classNames("_fp-career-contact__grid")}>
            <div className="_fp-career-contact__grid__image">
              {hasImage ? (
                <GatsbyImage
                  image={contact.gatsbyImage}
                  className={"_fp-career-contact__grid__image__image"}
                  alt={t(contact.image.description.key)}
                />
              ) : (
                <div className={"_fp-career-contact__grid__image__icon"}>
                  <EmployeesIcon />
                </div>
              )}
            </div>
            {isClient && (
              <div className="_fp-career-contact__grid__info">
                <h4
                  className={
                    "_fp-heading-3 _fp-career-contact__grid__info__name"
                  }
                >
                  {contact.name}
                </h4>
                <p
                  className={
                    "_fp-text--small _fp-career-contact__grid__info__responsibility"
                  }
                >
                  {contact.responsibility?.key && t(contact.responsibility.key)}
                </p>
                <p
                  className={
                    "_fp-heading-4 _fp-career-contact__grid__info__quote"
                  }
                >
                  {contact.quote?.key && t(contact.quote.key)}
                </p>
                <div className="_fp-career-contact__grid__info__phone">
                  {contact.phone && (
                    <>
                      {t("GLOBAL-PHONE_COLON")}&nbsp;
                      <a href={`tel:${contact.phone}`}>{contact.phone}</a>
                    </>
                  )}
                </div>
              </div>
            )}
          </div>
        </FullSizeTileContent>
      </Tile>
    </div>
  )
}

export default CareerContact

CareerContact.propTypes = {
  contact: PropTypes.any.isRequired,
}
