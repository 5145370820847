import { graphql } from "gatsby"
import { find } from "lodash"
import React from "react"

import CareerContact from "../components/career/career-contact/career-contact"
import { ImageTile } from "../components/career/image-tile/image-tile"
import JobOfferInformation from "../components/career/job-offers-information/job-offer-information"
import LocationPreview from "../components/location-preview/location-preview"
import OverviewGrid from "../components/overview-grid/overview-grid"
import SEO from "../components/seo"
import SoftgardenLocationPreview from "../components/softgarden-location-preview/softgarden-location-preview"
import Tile from "../components/tile/tile"
import SideNavView from "../components/views/side-nav-view/side-nav-view"
import useCareerContacts from "../hooks/useCareerContacts"
import useMunzingLocations from "../hooks/useMunzingLocation"
import { useTranslation } from "../i18n/translate"
import { SoftgardenJobModel } from "../model/career/softgardenJobModel"

const JobofferPage = ({ data, location }) => {
  const { t, i18n } = useTranslation()

  const locations = useMunzingLocations()
  const careerContacts = useCareerContacts()
  const jobOffer = new SoftgardenJobModel(
    data.softgardenJob,
    locations,
    careerContacts,
  )

  let addressComponents = []
  let city = ""
  let postalCode = ""
  let region = ""
  let street = ""
  let streetNumber = ""
  let country = ""
  if (
    jobOffer.locations?.length > 0 &&
    jobOffer.locations[0].location?.address_components
  ) {
    addressComponents = jobOffer.locations[0].location.address_components
    city = find(addressComponents, function (o) {
      return o.types.includes("locality")
    }).long_name
    postalCode = find(addressComponents, function (o) {
      return o.types.includes("postal_code")
    }).long_name
    region = find(addressComponents, function (o) {
      return o.types.includes("administrative_area_level_1")
    }).long_name
    street = find(addressComponents, function (o) {
      return o.types.includes("route")
    }).long_name
    streetNumber = find(addressComponents, function (o) {
      return o.types.includes("street_number")
    }).long_name
    country = find(addressComponents, function (o) {
      return o.types.includes("country")
    }).long_name
  }

  const employmentType = SoftgardenJobModel.getTranslatedCatalogValue(
    jobOffer.employmentTypes[0],
    i18n.language,
  )
  const workTime = SoftgardenJobModel.getTranslatedCatalogValue(
    jobOffer.workTimes[0],
    i18n.language,
  )
  const industry = SoftgardenJobModel.getTranslatedCatalogValue(
    jobOffer.industries[0],
    i18n.language,
  )

  return (
    <>
      <SEO
        pathName={location.pathname}
        titleTranslatedString={jobOffer.externalPostingName}
        descriptionTranslatedString={jobOffer.jobAdText}
      />
      <script
        type="application/ld+json"
        dangerouslySetInnerHTML={{
          __html: `{
                      "@context" : "https://schema.org/",
                      "@type" : "JobPosting",
                      "title" : "${jobOffer.externalPostingName}",
                      "description" : "${jobOffer.description}",
                      "datePosted":"${jobOffer.postingLastUpdatedDate.toISOString()}",
                      "validThrough":"${jobOffer.postingLastUpdatedDate
                        .add(2, "years")
                        .toISOString()}",
                      "jobStartDate": "${jobOffer.jobStartDate.toISOString()}",
                      "industry": "${industry}",
                      "baseSalary":{
                          "@type":"MonetaryAmount",
                          "currency":"EUR",
                          "value":{
                             "@type":"QuantitativeValue",
                             "minValue":0.0,
                             "maxValue":0.0,
                             "unitText":"MONTH"
                          }
                       },
                      "hiringOrganization" : {
                      "@type" : "Corporation",
                      "name" : "${
                        data.site.siteMetadata.organisationLegalName
                      }",
                      "sameAs" : "${data.site.siteMetadata.siteUrl}",
                      "logo" : "${data.site.siteMetadata.logoUrl}"
                  },
                      "employmentType":[
                          "${workTime}, ${employmentType}"
                       ]${
                         addressComponents.length > 0
                           ? `,
                       "jobLocation":{
                          "@type":"Place",
                          "address":{
                             "@type":"PostalAddress",
                             "streetAddress":"${street} ${streetNumber}",
                             "addressLocality":"${city}",
                             "postalCode":"${postalCode}",
                             "addressRegion":"${region}",
                             "addressCountry":"${country}"
                          }
                       }`
                           : ""
                       }
              }`,
        }}
      ></script>
      <SideNavView
        titleTranslatedString={jobOffer.externalPostingName}
        pathName={location.pathname}
      >
        <div className="_fp-grid _fp-grid--gap">
          <div className="_fp-col-12">
            <ImageTile
              image={jobOffer.jobAdImg?.childImageSharp?.gatsbyImageData}
            />
          </div>
          <div className="_fp-col-12">
            <JobOfferInformation
              jobOffer={jobOffer}
              topInnovator={data.topInnovator}
              topInnovatorThumbnail={data.topInnovatorThumbnail}
            />
          </div>
          {jobOffer.contactPerson && (
            <div className="_fp-col-12">
              <Tile
                maxContentWidth
                noTile
                titleTranslatedString={t("CAREER-CAREER_CONTACT")}
              >
                <CareerContact contact={jobOffer.contactPerson} />
              </Tile>
            </div>
          )}
          {jobOffer.locations?.length > 0 && (
            <div className="_fp-col-12">
              <Tile
                noTile
                titleTranslatedString={
                  jobOffer.locations?.length > 1
                    ? t("CAREER-JOB_OFFERS-LOCATIONS")
                    : t("CAREER-JOB_OFFERS-LOCATION")
                }
              >
                <OverviewGrid>
                  {jobOffer.locations
                    .sort((a, b) => {
                      const order = a.order - b.order
                      if (order === 0) {
                        return t(a.name.key).localeCompare(t(b.name.key))
                      } else {
                        return order
                      }
                    })
                    .map((location, index) => {
                      return location.url ? (
                        <LocationPreview
                          location={location}
                          key={"location " + index}
                        ></LocationPreview>
                      ) : (
                        <SoftgardenLocationPreview
                          jobOffer={jobOffer}
                          key={"location " + index}
                        />
                      )
                    })}
                </OverviewGrid>
              </Tile>
            </div>
          )}
        </div>
      </SideNavView>
    </>
  )
}

export default JobofferPage

export const query = graphql`
  query ($jobOfferId: String) {
    site {
      siteMetadata {
        siteUrl
        title
        author
        logoUrl
        organisationLegalName
        organisationAlternateName
        organisationName
      }
    }
    softgardenJob(id: { eq: $jobOfferId }) {
      ...SoftgardenJobParams
    }
    topInnovator: strapiDynamicPdfFile(strapiId: { eq: 6 }) {
      ...DynamicPdfFileParts
    }
    topInnovatorThumbnail: strapiDynamicImage(
      uid: { eq: "CAREER-TOP_INNOVATOR_2023" }
    ) {
      ...DynamicImageWithGatsbyImage
    }
  }
`
