import PropTypes from "prop-types"
import React from "react"

import { useTranslation } from "../../../i18n/translate"
import { SoftgardenJobModel } from "../../../model/career/softgardenJobModel"
import Button from "../../button/button"
import { DynamicPdfFile } from "../../dynamic-pdf-file/dynamic-pdf-file"
import Tile from "../../tile/tile"
import "./job-offer-information.sass"

const JobOfferInformation = ({
  jobOffer,
  topInnovator,
  topInnovatorThumbnail,
}) => {
  const offer = jobOffer

  const { t, i18n } = useTranslation()

  return (
    <Tile titleTranslatedString={offer.externalPostingName}>
      <DynamicPdfFile
        customClass={"_fp-job-offer-information__badge"}
        pdf={topInnovator}
        thumbnail={topInnovatorThumbnail}
      />
      <div
        className={
          "_fp-global-regular-font-formatting _fp-job-offer-information__richtext"
        }
        dangerouslySetInnerHTML={{ __html: offer.jobAdText }}
      ></div>

      <div className="_fp-job-offer-information__button-row">
        <Button
          buttonExternalLinkPath={jobOffer.applyOnlineLink}
          target={"_blank"}
          bold={true}
          buttonSize={"large"}
          buttonLabel={t("CAREER-APPLY_NOW")}
          className={"_fp-job-offer-information__apply-button"}
        />
      </div>
    </Tile>
  )
}

JobOfferInformation.propTypes = {
  jobOffer: PropTypes.instanceOf(SoftgardenJobModel),
}

export default JobOfferInformation
