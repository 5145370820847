import PropTypes from "prop-types"
import React from "react"

const FullSizeTileContent = ({ children }) => {
  return <div className={"_fp-tile__full-size-content"}>{children}</div>
}

FullSizeTileContent.propTypes = {
  children: PropTypes.node.isRequired,
}

export default FullSizeTileContent
