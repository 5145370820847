import { GatsbyImage } from "gatsby-plugin-image"
import React from "react"

import { Locale } from "../../i18n/locale"
import { useTranslation } from "../../i18n/translate"
import { dynamicImageWithGatsbyImage } from "../../util/dynamic-image"

export const getPdfFileItemForLanguage = (language, pdf) => {
  const pdfFileItems = pdf.pdf_file.pdf_file_items
  let pdfFileItem
  let fallbackItem = pdfFileItems[0]

  for (const pdfFileItem1 of pdfFileItems) {
    const key = new Locale(language).key

    if (key === "en") {
      fallbackItem = pdfFileItem1
    }
    if (key === Locale.fromPdfFileLanguage(pdfFileItem1.language).key) {
      pdfFileItem = pdfFileItem1
    }
  }

  return pdfFileItem || fallbackItem
}

export const getDynamicPdfFileUrl = (pdfFileItem, pdf) => {
  return `/documents/pdf/${
    Locale.fromPdfFileLanguage(pdfFileItem.language).key
  }/${pdf.name}-${pdf.strapiId}.pdf`
}

export const DynamicPdfFile = ({ pdf, customClass, thumbnail, ...props }) => {
  const { i18n } = useTranslation()
  const pdfFileItem = getPdfFileItemForLanguage(i18n.language, pdf)
  const localFile = pdfFileItem.file?.localFile

  return (
    <a
      {...props}
      target={"_blank"}
      href={getDynamicPdfFileUrl(pdfFileItem, pdf)}
      className={customClass}
    >
      <GatsbyImage
        image={
          thumbnail
            ? dynamicImageWithGatsbyImage(thumbnail)
            : localFile?.childScreenshot?.screenshotFile?.childImageSharp
                ?.gatsbyImageData
        }
        alt={pdf.name}
      />
    </a>
  )
}
