import { graphql, useStaticQuery } from "gatsby"

import { CareerContactModel } from "../model/careerContactModel"

const useCareerContacts = () => {
  return useStaticQuery(graphql`
    query CareerContactsQuery {
      allStrapiCareerContacts {
        nodes {
          name
          email
          phone
          responsibility {
            key
          }
          quote {
            key
          }
          image {
            description {
              key
            }
            image {
              localFile {
                childImageSharp {
                  gatsbyImageData(layout: FULL_WIDTH)
                }
              }
            }
          }
        }
      }
    }
  `).allStrapiCareerContacts.nodes.map((contact) => {
    return new CareerContactModel({
      ...contact,
      gatsbyImage:
        contact.image?.image?.localFile?.childImageSharp?.gatsbyImageData,
    })
  })
}

export default useCareerContacts
