import { graphql, useStaticQuery } from "gatsby"

import { MunzingLocationModel } from "../model/munzingLocationModel"

const useMunzingLocations = (t) => {
  const res = useStaticQuery(graphql`
    query MunzingLocationsQuery {
      allStrapiMunzingLocations {
        edges {
          node {
            description {
              key
            }
            short_description {
              key
            }
            brands {
              id
            }
            name {
              key
            }
            nav_item {
              uid
              url
              order
              name {
                key
              }
            }
            strapiId
            contacts {
              ...MinimalContactParams
            }
            location {
              ...GoogleMapsLocationParams
            }
            image_resources {
              description {
                key
              }
              image {
                localFile {
                  childImageSharp {
                    gatsbyImageData(layout: FULL_WIDTH)
                  }
                }
              }
            }
          }
        }
      }
    }
  `).allStrapiMunzingLocations.edges.map(
    ({ node }) => new MunzingLocationModel(node),
  )

  if (t) {
    return res.sort((a, b) => {
      const order = a.order - b.order
      if (!order || order === 0) {
        return t(a.name.key).localeCompare(t(b.name.key))
      } else {
        return order
      }
    })
  }

  return res
}

export default useMunzingLocations
